import { http } from '@/app/helpers/axios-instance';
import { toPageable, toPagination } from '@/app/helpers/pagination';

export const sendMessage = (message) =>
  http.post(`/v1/messages/send`, message)
    .then(response => response.data);

export const fetchOutbox = ({ pagination = {} }) =>
  http
    .get('/v1/messages/outbox', { params: { ...toPageable(pagination) } })
    .then(response => toPagination(response.data));

export const fetchInbox = ({ pagination = {} }) =>
  http
    .get('/v1/messages/inbox', { params: { ...toPageable(pagination) } })
    .then(response => toPagination(response.data));

export const fetchConversations = ({ pagination = {} }) =>
  http
    .get('/v1/messages/conversations', { params: { ...toPageable(pagination) } })
    .then(response => toPagination(response.data));

export const fetchConversationMessages = (uuid, { pagination = {} }) => {
  console.log(uuid);
  return http
    .get(`/v1/messages/conversations/${uuid}`, { params: { ...toPageable(pagination) } })
    .then(response => toPagination(response.data));
}

export const fetchOutboxFlirts = ({ pagination = {} }) =>
  http
    .get('/v1/messages/flirts/outbox', { params: { ...toPageable(pagination) } })
    .then(response => toPagination(response.data));

export const fetchInboxFlirts = ({ pagination = {} }) =>
  http
    .get('/v1/messages/flirts/inbox', { params: { ...toPageable(pagination) } })
    .then(response => toPagination(response.data));

export const fetchToReplyConversations = ({ pagination = {} }) =>
  http
    .get('/v1/messages/conversations/to-reply', { params: { ...toPageable(pagination) } })
    .then(response => toPagination(response.data));

export const sendReply = (message) =>
  http.post(`/v1/messages/send-reply`, message)
    .then(response => response.data);